<template>
  <b-row>
    <b-col class="mt-1" cols="12">
      <label class="col-form-label-sm field-name-class">
        {{ $t('components.form.playground.activities-section.booking-type') }}
      </label>
    </b-col>
    <b-col cols="12">
      <select
        v-model="playground.bookingType"
        class="background-light-blue-inputs form-control-sm form-control"
      >
        <option value="unique">
          {{ $t('components.form.playground.activities-section.unique-booking-type') }}
        </option>
        <option value="multiple">
          {{ $t('components.form.playground.activities-section.multiple-booking-type') }}
        </option>
      </select>
    </b-col>
  </b-row>
</template>
<script>

export default {
  props: {
    playground: {
      type: Object,
      default: () => {
      }
    }
  },
}
</script>
